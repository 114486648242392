<template>
  <div class="page-container">
    <div class="section-top section-top-lg">
      <div class="title-holder">
        <a href="/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">
            <i class="icon icon-key"></i>
            {{$ml.with('VueJS').get('orderServices')}}
          </span>
        </h1>
      </div>
      <div class="sort-holder">
        <div class="sort-title">{{$ml.with('VueJS').get('sortBy')}}:</div>
        <ul class="sort-list">
          <li
            class="sort-list-item"
            v-bind:class="{active: disabled === 'data'}"
          >
            <span class="sort-list-item-link" v-on:click="disabled='data'">{{$ml.with('VueJS').get('byDate')}}</span>
          </li>
          <li
            class="sort-list-item"
            v-bind:class="{active: disabled === 'status'}"
          >
            <span class="sort-list-item-link"
                  v-on:click="disabled='status'"
            >{{$ml.with('VueJS').get('byStatus')}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="section-main">
      <div class="request-items">
        <template v-for="requestdd in sortedList">
          <a :key="requestdd.num" :href="'#/Request/' + requestdd.num" class="request-item">
            <span class="request-item-top">
              <span class="request-item-num">№{{requestdd.num}} <!--{{requestdd.text}}--> від {{requestdd.date}}</span>
              <span class="request-status" v-bind:class="requestdd.statusClass"> {{requestdd.status}}</span>
            </span>
            <!-- <span class="request-item-text">{{requestdd.repair}}</span> -->
            <span class="request-item-text">{{requestdd.technicians}} {{requestdd.brand}} {{requestdd.model}}</span>
            <span class="request-item-text">{{requestdd.serial}}</span>
          </a>
        </template>
      </div>
    </div>
    <div class="section-bottom">
      <a href="#/NewRequest" class="btn btn-full-width btn-main">{{$ml.with('VueJS').get('createNewRequest')}}</a>
    </div>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
      disabled: 'data',
      sortParam: '',
      isActive: true,
      requests: []
    }
  },
  methods: {
    async loadJsonService () {
      await this.serviceJson.get().then(response => response.json())
        .then(response => {
          for (var item in response) {
            // response[item].statusId = 1
            let status = response[item].statusId
            switch (status) {
              case '1':
                response[item].statusClass = 'status-new'
                break
              case '2':
                response[item].statusClass = 'status-inwork'
                break
              case '3':
                response[item].statusClass = 'status-canceled'
                break
              case '4':
                response[item].statusClass = 'status-done'
                break
              default:
                response[item].statusClass = 'status-new'
                break
            }
            if (response[item].brand === 'null') {
              response[item].brand = ''
            }
            if (response[item].model === 'null') {
              response[item].model = ''
            }
            if (response[item].technicians === 'null') {
              response[item].technicians = ''
            }
            if (response[item].serial === 'null') {
              response[item].serial = ''
            }

            let date = new Date(response[item].date)
            let monthstr = ''
            let month = parseInt(date.getMonth() + 1)
            if (month <= 9) {
              monthstr = '0' + month
            } else {
              monthstr = '' + month
            }

            let datestr = ''
            let datedate = date.getDate()
            if (datedate <= 9) {
              datestr = '0' + datedate
            } else {
              datestr = '' + datedate
            }
            response[item].date = datestr + '.' + monthstr + '.' + date.getFullYear()
          }
          this.requests = response
          console.log(this.requests)
        })
      this.Loading = false
    },
    checkAuth: function () {
      if (localStorage.userId) {
        this.users = this.$resource('https://nfm.com.ua/index.php?route=api/app/users/' + localStorage.userId)
        this.users.get().then(response => response.json())
          .then(response => {
            if (typeof response === 'object' && response.length === 0) {
              localStorage.clear()
            }
          })
      }
    }
  },
  mounted () {
    this.loadJsonService()
    this.checkAuth()
  },
  computed: {
    sortedList () {
      console.log('sortedList')
      switch (this.disabled) {
        case 'data':
          return this.requests.sort(function (obj1, obj2) {
            return obj2.num - obj1.num
          })
        case 'status':
          return this.requests.sort(function (obj1, obj2) {
            return obj1.statusId - obj2.statusId
          })
        default:
          return this.requests
      }
    }
  },
  created () {
    if (localStorage.getItem('activeItem') === '1') {
      this.serviceJson = this.$resource('service/ua/' + localStorage.userId)
    } else {
      this.serviceJson = this.$resource('service/ua/' + localStorage.userId)
    }
  }
}
</script>
