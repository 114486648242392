<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Search" class="link-back">
          <i class="icon icon-close"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{ $ml.with("VueJS").get("cart") }}</span>
        </h1>
        <span
          class="link-red link-clear"
          @click="$modal.show('modal-basket')"
          >{{ $ml.with("VueJS").get("clear") }}</span
        >
      </div>
    </div>
    <div class="section-main">
      <div class="product-items basket-items">
        <form id="basket-form">
          <swipe-list
            ref="list"
            :disabled="!enabled"
            :items="itemsSelected"
            item-key="id"
          >
            <template v-slot="{ item, index, revealRight, closeActions }">
              <div
                class="product-item-holder"
                v-bind:key="item.id"
                v-bind:class="{ 'not-available': !item.statusAvailable }"
              >
                <div class="product-item">
                  <!--                  <a :href="item.href" class="product-item-link">-->
                  <span class="product-item-name">{{ item.name }}</span>
                  <!--                  </a>-->
                  <span class="product-item-code">Арт. {{ item.article }}</span>
                  <div class="product-item-price" v-if="true">
                    <div class="product-item-price-num">
                      {{ (item.price * item.count).toFixed(2) | format }} грн
                    </div>
                    <div class="product-item-price-text">
                      {{ $ml.with("VueJS").get("VAT") }}
                    </div>
                  </div>
                  <div class="product-item-price" v-else>
                    <div class="product-item-price-text">
                      {{ $ml.with("VueJS").get("checkPrice") }}
                    </div>
                  </div>
                  <div class="product-item-buy">
                    <div class="product-item-count">
                      <number-input
                        v-model="item.count"
                        :min="1"
                        inline
                        controls
                        rounded
                      ></number-input>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:right="{ item }">
              <div
                class="swipeout-action remove"
                title="remove"
                @click="remove(item)"
              >
                <div class="swipeout-action-holder">
                  <i class="icon icon-close-remove"></i>
                  <span class="swipeout-action-text">{{
                    $ml.with("VueJS").get("deleteProduct")
                  }}</span>
                </div>
              </div>
            </template>
          </swipe-list>
        </form>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" />
    <div class="section-bottom">
      <div class="basket-info">
        <span class="basket-info-text">{{
          $ml.with("VueJS").get("basketMsg")
        }}</span>
      </div>
      <button
        v-on:click="submitForm()"
        type="submit"
        class="btn btn-full-width btn-main"
        form="basket-form"
        v-bind:class="{ disabled: btnDisable }"
      >
        {{ $ml.with("VueJS").get("checkoutFor") }}
        <span v-if="newTotalVal">{{ newTotalVal.toFixed(2) | format }}</span>
        <span v-else>{{ totalVal.toFixed(2) | format }}</span>
        грн
      </button>
    </div>
    <modal-basket></modal-basket>
  </div>
</template>

<script>
import { SwipeList } from "vue-swipe-actions";
import ModalBasketDel from "../modal/ModalBasketDel";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
import modal from "../modal/ModalErrorSender";
export default {
  data() {
    return {
      resultSender: "",
      isModalVisible: false,
      newTotalVal: null,
      productsId: [],
      itemsSelected: [],
      enabled: true,
      btnDisable: false
    };
  },
  components: {
    "modal-basket": ModalBasketDel,
    SwipeList,
    modal
  },
  filters: {
    format(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ");
    }
  },
  computed: {
    totalVal: function() {
      let newVal = 0;
      console.log("totalVal");
      console.log(this.itemsSelected);
      this.itemsSelected.forEach(function(element) {
        if (element.quantity > 0) {
          console.log(element);
          newVal += element.price * element.count;
        }
      });
      if (!newVal) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.btnDisable = true;
      }
      return newVal;
    },
    clonedItems: function() {
      return JSON.parse(JSON.stringify(this.itemsSelected));
    }
  },
  watch: {
    clonedItems: function(newVal, oldVal) {
      let newValue = 0;
      newVal.forEach(function(el, index) {
        if (el.quantity > 0) {
          newValue += el.price * el.count;
        }
      });
      this.newTotalVal = newValue;

      if (!this.newTotalVal) {
        this.btnDisable = true;
      }
      return this.newTotalVal;
    }
  },
  mounted() {
    this.getLocalStorage();
    this.loadProduct();
    // this.$refs.list.revealRight(0)
    let that = this;
    setTimeout(function() {
      that.$refs.list.closeActions(0);
    }, 1000);
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    deleteProductCart(idProduct) {
      var cartProductId = JSON.parse(localStorage.getItem("itemsIdBasket"));
      delete cartProductId[idProduct];
      localStorage.setItem("itemsIdBasket", JSON.stringify(cartProductId));
    },
    getLocalStorage() {
      if (localStorage.getItem("itemsIdBasket")) {
        var cartProductId = JSON.parse(localStorage.getItem("itemsIdBasket"));
        for (var id in cartProductId) {
          // console.log(id)
          this.productsId.push(id);
        }
        // console.log(this.productsId)
      }
    },
    async loadProduct() {
      let summ = 0;
      let article = [];
      let statusArray = [];
      for (let i in this.productsId) {
        await this.$http
          .get(
            "https://nfm.com.ua/index.php?route=api/app/products/" +
              this.productsId[i] + 
              '&token=' + 
              localStorage.userToken + 
              '&id=' + 
              localStorage.userId
          )
          .then(response => response.json())
          .then(response => {
            article.push(response.article);
            this.$http
              .get(
                "https://nfm.com.ua/index.php?route=api/app/search&q=" +
                  article[i] +
                  "&token=" +
                  localStorage.userToken +
                  "&id=" +
                  localStorage.userId
              )
              .then(resp => resp.json())
              .then(resp => {
                for (var inx in resp) {
                  if (resp[inx].id === this.productsId[i]) {
                    response.price = resp[inx].price;
                    response.quantity = parseInt(resp[inx].quantity);
                    var itemsIdBasket = JSON.parse(
                      localStorage.getItem("itemsIdBasket")
                    );
                    if (response.quantity > 0) {
                      summ += response.price * response.count;
                      response.statusAvailable = true;
                    } else {
                      response.statusAvailable = false;
                    }
                    response.count = itemsIdBasket[this.productsId[i]].count;
                    statusArray.push(response.statusAvailable);
                    this.itemsSelected.push(response);
                    this.btnDisable = statusArray.every(function(el) {
                      return el === false;
                    });
                    this.loading = false;
                  }
                }
                this.newTotalVal = summ;
              });
          });
      }
    },
    remove(item) {
      let idProductObject = this.itemsSelected.filter(i => i === item);
      let resultIdProduct = idProductObject[0].id;
      this.itemsSelected = this.itemsSelected.filter(i => i !== item);
      this.deleteProductCart(resultIdProduct);
    },
    submitForm() {
      var itemsIdBasket = JSON.parse(localStorage.getItem("itemsIdBasket"));
      var cartProductIdSuccess = {};
      var cartProductIdError = {};
      this.itemsSelected.forEach(function(element) {
        //
        if (parseInt(element.quantity) > 0) {
          cartProductIdSuccess[element.id] = {};
          cartProductIdSuccess[element.id].count =
            itemsIdBasket[element.id].count;
          cartProductIdSuccess[element.id].price =
            itemsIdBasket[element.id].price;
        } else {
          cartProductIdError[element.id] = {};
          cartProductIdError[element.id].count =
            itemsIdBasket[element.id].count;
          cartProductIdError[element.id].price =
            itemsIdBasket[element.id].price;
        }
      });
      var formdata = new FormData();
      formdata.append("user_id", localStorage.userId);
      var i = 0;
      for (var id in cartProductIdSuccess) {
        formdata.append("order[" + i + "][product_id]", id);
        formdata.append(
          "order[" + i + "][count]",
          cartProductIdSuccess[id].count
        );
        i++;
      }
      console.log(formdata);
      // console.log(JSON.parse(localStorage.getItem('itemsIdBasket')))
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };
      fetch(
        "https://nfm.com.ua/index.php?route=api/app/spare_parts/",
        requestOptions
      )
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log("error", error));
      if (cartProductIdError.length === 0) {
        localStorage.setItem(
          "itemsIdBasket",
          JSON.stringify(cartProductIdError)
        );
      } else {
        localStorage.removeItem("itemsIdBasket");
      }
      // console.log(localStorage)
      this.$router.push("SuccessSendForm");
    }
  }
  // created () {
  //   // for (let id in this.productsId) {
  //   this.resource = this.$resource('products/' + '11237')
  //   // }
  // }
};
</script>
