<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Login" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{ title }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="form-holder">
        <form id="registration-form" @submit.prevent="submitRegistration()">
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userName.$error }"
          >
            <input
              type="text"
              id="user_name"
              name="user_name"
              v-model="userName"
              @input="$v.userName.$touch()"
              @blur="$v.userName.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userName.$model }"
            />
            <label for="user_name" class="label">ПІБ</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userName.$error"
            ></span>
            <div class="error" v-if="$v.userName.$error">Вкажіть ПІБ</div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userEmail.$error }"
          >
            <input
              id="user_email"
              name="user_email"
              type="text"
              v-model="userEmail"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userEmail.$model }"
            />
            <label for="user_email" class="label">E-mail</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userEmail.$error"
            ></span>
            <div class="error" v-if="$v.userEmail.$error">Вкажіть E-mail</div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userRegion.$error }"
          >
            <the-mask
              mask="+38 (0##) ###-##-##"
              id="user_phone"
              name="user_phone"
              type="tel"
              v-model="userPhone"
              @input="$v.userPhone.$touch()"
              @blur.native="$v.userPhone.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userPhone.$model }"
            />
            <label for="user_phone" class="label">Телефон</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userPhone.$error"
            ></span>
            <div class="error" v-if="$v.userPhone.$error">Вкажіть телефон</div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userRegion.$error }"
          >
            <v-select
              id="user_region"
              name="user_region"
              class="select"
              label="regionName"
              :options="region"
              :searchable="false"
              :clearable="false"
              :components="{ OpenIndicator }"
              @change="$v.select.$touch()"
              @blur="$v.userRegion.$touch()"
              v-model="userRegion"
              v-bind:class="{ 'has-value': $v.userRegion.$model }"
              :reduce="regionName => regionName.regionCode"
            ></v-select>
            <label for="user_region" class="label">Область</label>
            <div class="error" v-if="$v.userRegion.$error">
              Вкажіть вашу Область
            </div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userNumber.$error }"
          >
            <input
              id="user_number"
              name="user_number"
              type="text"
              v-model="userNumber"
              @input="$v.userNumber.$touch()"
              @blur="$v.userNumber.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userNumber.$model }"
            />
            <label for="user_number" class="label">ЄДРПОУ</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userNumber.$error"
            ></span>
            <div class="error" v-if="$v.userNumber.$error">
              Вкажіть ЄДРПОУ. Тільки цифри
            </div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userCompany.$error }"
          >
            <input
              id="user_company"
              name="user_company"
              type="text"
              v-model="userCompany"
              @input="$v.userCompany.$touch()"
              @blur="$v.userCompany.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userCompany.$model }"
            />
            <label for="user_company" class="label">Назва підприємства</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userCompany.$error"
            ></span>
            <div class="error" v-if="$v.userCompany.$error">
              Вкажіть назву підприємства
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" />
    <div class="section-bottom">
      <button
        type="submit"
        form="registration-form"
        class="btn btn-full-width btn-main"
      >
        Зареєструватись
      </button>
    </div>
  </div>
</template>

<script>
import { required, email, helpers } from "vuelidate/dist/validators.min";
import { TheMask } from "vue-the-mask";
import modal from "../modal/ModalErrorSender";
const phone = helpers.regex("phone", /^[0-9]{9}$/);
const num = helpers.regex("num", /^[0-9]{4,}$/);

export default {
  components: {
    TheMask,
    modal
  },
  data() {
    return {
      isModalVisible: false,
      title: "Реєстрація",
      userName: null,
      userEmail: null,
      userPhone: null,
      userRegion: null,
      userNumber: null,
      userCompany: null,
      submitStatus: null,
      OpenIndicator: {
        render: createElement => createElement("span")
      },
      region: [
        {
          regionCode: "300001",
          regionName: "Вінницька область"
        },
        {
          regionCode: "300002",
          regionName: "Волинська область"
        },
        {
          regionCode: "300003",
          regionName: "Дніпропетровська область"
        },
        {
          regionCode: "300032",
          regionName: "Донецька область"
        },
        {
          regionCode: "300005",
          regionName: "Житомирська область"
        },
        {
          regionCode: "300006",
          regionName: "Закарпатська область"
        },
        {
          regionCode: "300007",
          regionName: "Запорізька область"
        },
        {
          regionCode: "300008",
          regionName: "Івано-Франківська область"
        },
        {
          regionCode: "300009",
          regionName: "Київська область"
        },
        {
          regionCode: "300010",
          regionName: "Кіровоградська область"
        },
        {
          regionCode: "140420",
          regionName: "Луганська область"
        },
        {
          regionCode: "300012",
          regionName: "Львівська область"
        },
        {
          regionCode: "300013",
          regionName: "Миколаївська область"
        },
        {
          regionCode: "300014",
          regionName: "Одеська область"
        },
        {
          regionCode: "300015",
          regionName: "Полтавська область"
        },
        {
          regionCode: "300016",
          regionName: "Рівненська область"
        },
        {
          regionCode: "300017",
          regionName: "Сумська область"
        },
        {
          regionCode: "300018",
          regionName: "Тернопільська область"
        },
        {
          regionCode: "300019",
          regionName: "Харківська область"
        },
        {
          regionCode: "300020",
          regionName: "Херсонська область"
        },
        {
          regionCode: "300021",
          regionName: "Хмельницька область"
        },
        {
          regionCode: "300022",
          regionName: "Черкаська область"
        },
        {
          regionCode: "300024",
          regionName: "Чернігівська область"
        },
        {
          regionCode: "300023",
          regionName: "Чернівецька область"
        }
      ]
    };
  },
  validations: {
    userName: {
      required
    },
    userEmail: {
      required,
      email
    },
    userPhone: {
      required,
      phone
    },
    userRegion: {
      required
    },
    userNumber: {
      required,
      num
    },
    userCompany: {
      required
    }
  },
  mounted() {
    // this.setLocalStorage()
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    setLocalStorage() {
      localStorage.setItem("userName", this.userName);
      localStorage.setItem("userEmail", this.userEmail);
      localStorage.setItem("userPhone", this.userPhone);
      localStorage.setItem("userRegion", this.userRegion);
      localStorage.setItem("userNumber", this.userNumber);
      localStorage.setItem("userCompany", this.userCompany);
    },
    submitRegistration() {
      const result = {
        name: this.userName,
        "e-mail": this.userEmail,
        phone: this.userPhone,
        region: this.userRegion,
        egrpou: this.userNumber,
        company_name: this.userCompany
      };
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$v.$reset();
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
          this.setLocalStorage();
          this.sendingData(result);
          // console.log(localStorage)
        });
      }
    },
    async sendingData(result) {
      let res = "";
      await this.$http
        .post("https://nfm.com.ua/index.php?route=api/app/users", result, {
          emulateJSON: true
        })
        .then(response => {
          res = response;
        })
        .catch(response => {
          res = response;
        });
      if (res.status === 200) {
        if (res.body.status === "user_already_exists") {
          this.showModal();
        } else {
          this.$router.push("SuccessRegistration");
        }
      } else {
        this.showModal();
      }
    }
  }
};
</script>
