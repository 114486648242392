<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">
            <i class="icon icon-repair-part"></i>
            {{$ml.with('VueJS').get('labelSpareParts')}}
          </span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <form id="search" class="form-search">
        <div class="form-group-search">
          <div class="form-group-search">
            <v-autocomplete
              :items="items"
              v-model="itemsss"
              :get-label="getLabel"
              :min-len="4"
              :wait="500"
              @update-items="update"
              :component-item="tpl"
              @item-selected="itemSelected"
              @item-clicked="itemClicked"
              :input-attrs="{name: 'input-test' , id: 'v-my-autocomplete', placeholder: $ml.with('VueJS').get('skuOrName')}"
              class="input input-search"
              @blur="inputFocus = false"
              @focus="inputFocus = true"
            ></v-autocomplete>

            <button type="submit" class="btn btn-search" v-on:click="rederectSearch"
                    v-bind:class="{'btn-active': inputFocus}"
            >{{$ml.with('VueJS').get('find')}}</button>
          </div>
        </div>
      </form>
    </div>
    <Cart v-bind:countCart="countCartt()"/>
  </div>
</template>

<script>
import tpl from './ItemTemplate.vue'
// import Parts from './repair.js'
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  },
  computed: {
    itemsss () {
      return this.searchText
    }
  },
  data () {
    return {
      itemsApi: [],
      basketVal: 0,
      items: [],
      tpl: tpl,
      resultcategoriesJson: '',
      inputFocus: false
    }
  },
  methods: {
    mounted () {
      this.checkAuth()
    },
    countCartt () {
      let countSum = 0
      if (localStorage.getItem('itemsIdBasket')) {
        let basketValss = JSON.parse(localStorage.getItem('itemsIdBasket'))
        for (var ss in basketValss) {
          countSum += basketValss[ss].count
        }
        this.basketVal = countSum
        return this.basketVal
      }
    },
    setLocalStorage (text) {
      localStorage.setItem('search', text)
    },
    rederectSearch (e) {
      e.preventDefault()
      this.$router.replace('/SearchItemsResult')
    },
    async loadSearch (search) {
      await this.$http.get('https://nfm.com.ua/index.php?route=api/app/search&q=' + search + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
        .then(response => response.json())
        .then(response => {
          this.resultcategoriesJson = response
        })
    },
    itemSelected (item) {
      console.log('Selected item!', item)
    },
    itemClicked (item) {
      console.log('You clicked an item!', item)
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    update (text) {
      this.loadSearch(text.toLowerCase())
      this.setLocalStorage(text.toLowerCase())
      this.items = this.resultcategoriesJson.filter(item => {
        return new RegExp(text.toLowerCase()).test(item.name.toLowerCase())
      })
    },
    checkAuth: function () {
      if (localStorage.userId) {
        this.users = this.$resource('https://nfm.com.ua/index.php?route=api/app/users/' + localStorage.userId)
        this.users.get().then(response => response.json())
          .then(response => {
            if (typeof response === 'object' && response.length === 0) {
              localStorage.clear()
            }
          })
      }
    }
  }
}
</script>
