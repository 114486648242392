const baseApiUrl = "https://nfm.com.ua/index.php?route=api/app"
const autoCompleteApiUrl = "https://nfm.com.ua/index.php?route=account"

export const GET_REGIONS = async () => {
  return fetch(`${baseApiUrl}/getRegions`, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_DISTRICTS = async regionId => {
  return fetch(`${baseApiUrl}/getDistricts/${regionId}`, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_SERVICE_CENTRES = async () => {
  return fetch(`${baseApiUrl}/getServices/ua`, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_HOLDINGS = async (userId) => {
  return fetch(`${baseApiUrl}/children/ua/${userId}`, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_AUTOCOMPLETE_BRANDS = async (text, userId) => {
  return fetch(`${autoCompleteApiUrl}/application_create/autocompleteBrand&filter_brand=${text || ''}&filter_customer_id=${userId} `, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_AUTOCOMPLETE_TYPES = async (text, brand, userId) => {
  return fetch(`${autoCompleteApiUrl}/application_create/autocompleteName&filter_brand=${brand}&filter_name=${text || ''}&filter_customer_id=${userId}  `, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_AUTOCOMPLETE_MODELS = async (text, brand, type, userId) => {
  return fetch(`${autoCompleteApiUrl}/application_create/autocompleteModel&filter_brand=${brand}&filter_name=${type}&filter_model=${text || ''}&filter_customer_id=${userId} `, {
    method: "GET",
    cache: 'no-cache'
  });
};

export const GET_AUTOCOMPLETE_SERIALS = async (text, brand, type, model, userId) => {
  return fetch(`${autoCompleteApiUrl}/application_create/autocompleteSerial&filter_brand=${brand}&filter_name=${type}&filter_model=${model}&filter_serial=${text || ''}&filter_customer_id=${userId}`, {
    method: "GET",
    cache: 'no-cache'
  });
};