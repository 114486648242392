<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <h1 class="title">
          <a href="#/Login" class="link-back">
            <i class="icon icon-arrow-back"></i>
          </a>
          <span class="title-item">{{ title }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main-form">
      <div class="form-holder">
        <form @submit.prevent="sendEmail()">
          <div class="form-group">
            <p class="form-text center" :style="txtColor">{{ text }}</p>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userEmail.$error }"
          >
            <input
              id="user_email"
              name="user_email"
              type="text"
              v-model="userEmail"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userEmail.$model }"
            />
            <label for="user_email" class="label">E-mail</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userEmail.$error"
            ></span>
            <div class="error" v-if="$v.userEmail.$error">Вкажіть E-mail</div>
          </div>
          <div class="button-row">
            <button type="submit" class="btn btn-main btn-full-width">
              Відправити
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/dist/validators.min";

export default {
  data() {
    return {
      title: "Відновлення паролю",
      text: "Введіть вашу пошту і ми надішлемо на неї новий пароль",
      userEmail: null,
      submitStatus: null,
      txtColor: {
        color: "#000000"
      }
    };
  },
  validations: {
    userEmail: {
      required,
      email
    }
  },
  methods: {
    setLocalStorage() {
      localStorage.setItem("userEmail", this.userEmail);
    },
    sendEmail() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$v.$reset();
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
          this.setLocalStorage();
          this.sendingData();
        });
      }
    },
    async sendingData() {
      var myHeaders = new Headers();
      var urlencoded = new URLSearchParams();
      urlencoded.append("reset_email", this.userEmail);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      fetch(
        "https://nfm.com.ua/index.php?route=api/app/recoverypass",
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.text = "Введите вашу почту и мы отправим на нее новый пароль";
            this.txtColor = {
              color: "#000000"
            };
            this.$router.push("SuccessPassword");
          } else {
            this.text = result.message;
            this.txtColor = {
              color: "#c70629"
            };
          }
        })
        .catch(error => console.log("error", error));
    }
  }
};
</script>
