@ -0,0 +1,307 @@
<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{ title }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main-form">
      <div class="form-holder">
        <form
          @submit.prevent="submitUserForm()"
          id="user-info"
          ref="form"
          method="post"
        >
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userEmail.$error }"
          >
            <input
              id="user_email"
              name="user_email"
              type="text"
              v-model="userEmail"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userEmail.$model }"
            />
            <label for="user_email" class="label">Ваш логін</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userEmail.$error"
            ></span>
            <div class="error" v-if="$v.userEmail.$error">Вкажіть логин</div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userPassword.$error }"
          >
            <input
              id="password"
              name="password"
              :type="pass ? 'text' : 'password'"
              v-model="userPassword"
              @input="$v.userPassword.$touch()"
              @blur="$v.userPassword.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userPassword.$model }"
            />
            <label for="password" class="label">Ваш пароль</label>
            <span
              class="icon icon-pass"
              v-bind:class="{ 'pass-hide': pass }"
              @click="showPassword"
            ></span>
            <div class="error" v-if="$v.userPassword.$error">
              Введіть пароль
            </div>
          </div>
          <div class="error-message" v-if="isUserValid">
            Неправильно введено логін або пароль
          </div>
          <div class="error-message" v-if="isNoUser">
            Користувача з таким email не існує
          </div>
          <div class="login-buttons">
            <div class="forgot-button">
                <button
                @click="emailLoginForm()"
                type="button"
                class="link-red link-center"
                form="user-info"
              >
                Вхід по телефону
              </button>
              <a href="#/PasswordRecovery" class="link-red link-center"
                >Забули пароль?</a
              >
            </div>
            <div class="button-row">
              <button
                type="submit"
                class="btn btn-main btn-full-width"
                form="user-info"
              >
                Війти
              </button>
            </div>
            <div class="button-row">
              <button
                @click="registrationForm()"
                type="button"
                class="btn-border btn-full-width"
                form="user-info"
              >
                Зареєструватися
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<style>
.login-buttons {

}
.forgot-button {
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.btn-border {
  border: 1px solid #c70629;
  padding: 16px 10px;
  background: #fff;
  color: #000;
  display: block;
  font-weight: bold;
}
.link-center {
  text-align: center;
  display: block;
  background: transparent;
  border: none;
}
.section-main-form {
  /*padding: 50% 0 0px;*/
  padding: 55px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}
.button-row {
  margin: 0 0 15px;
}
.error-message {
  margin: -20px 0 35px;
  font-size: 13px;
  line-height: 16px;
  color: #c70629;
}
</style>

<script>
import { required, email } from "vuelidate/dist/validators.min";
import modal from "../modal/ModalErrorConnecting";

export default {
  data() {
    return {
      title: "Вхід",
      userEmail: null,
      currentUser: false,
      userPassword: null,
      result: "",
      userToken: null,
      isModalVisible: false,
      isUserValid: false,
      usersList: null,
      userId: null,
      isNoUser: false,
      submitStatus: null,
      pass: false
      // routeTest: this.$route
    };
  },
  components: {
    modal
  },
  validations: {
    userEmail: {
      required,
      email
    },
    userPassword: {
      required
    }
  },
  methods: {
    saveLocalStorage() {
      if (localStorage.userEmail) {
        this.userEmail = localStorage.userEmail;
      }
      if (localStorage.userPassword) {
        this.userPassword = localStorage.userPassword;
      }
    },
    emailLoginForm() {
      this.$router.push("/Login");
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async submitUserForm() {
      const user = {
        email: localStorage.getItem("userEmail") === 'undefined' ? this.userEmail : localStorage.getItem("userEmail"),
        password: localStorage.getItem("userPassword") === 'undefined' ? this.userPassword : localStorage.getItem("userPassword")
      };
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$v.$reset();
        this.submitStatus = "PENDING";

        window.profile = {
          userEmail: this.userEmail,
          userPassword: this.userPassword
        };
        // this.currentUser = true
        // await this.$http.post('https://request.turumburum.com/auth/?login=' + this.userEmail + '&pass=' + this.userPassword, user)
        await this.$http
          .post("https://nfm.com.ua/index.php?route=api/app/login", user, {
            emulateJSON: true
          })
          .then(response => {
            this.result = response;
          })
          .catch(response => {
            this.result = response;
          });

        if (this.result.status === 200) {
          if (this.result.body.status === "ok") {
            // if (this.result.body.status) {

            // await this.getUserId();
            this.userId = this.result.body.id
            localStorage.setItem("userId", this.result.body.id);
            console.log('userId: ' + this.userId)
            if (this.userId === null) {
              this.isNoUser = true;
            } else {
              this.isNoUser = false;
              this.isUserValid = false;
              this.userToken = this.result.body.token;
              localStorage.setItem("userToken", this.userToken);
              this.$router.push("/");
              this.submitStatus = "OK";
              document.cookie = "userToken=" + this.userToken;
              document.cookie = "userId=" + this.userId;
              // console.log(document.cookie)
            }
          } else {
            this.isUserValid = true;
          }
        } else {
          this.showModal();
        }
        // }, 500)
      }
    },
    async getUserId() {
      let emailUser = this.userEmail;
      let userIdLocal = null;
      await this.$http
        .get("https://nfm.com.ua/index.php?route=api/app/users")
        .then(response => response.json())
        .then(response => {
          this.usersList = response;
          this.usersList.forEach(function(item, i, arr) {
            if (item.email === emailUser) {
              userIdLocal = item.id;
              return userIdLocal;
            } else {
              return false;
            }
          });
          this.userId = userIdLocal;
          localStorage.setItem("userId", this.userId);
        });
    },
    registrationForm() {
      this.$router.push("/Registration");
    },
    showPassword() {
      this.pass = !this.pass;
    }
  },
  mounted() {
    this.saveLocalStorage();
  },
  watch: {
    userEmail(newEmail) {
      localStorage.userEmail = newEmail;
    },
    userPassword(userPassword) {
      localStorage.userPassword = userPassword;
    }
  }
};
</script>