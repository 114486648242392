<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/TrainingRegistration_4" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('recording5')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="selecting-title">{{$ml.with('VueJS').get('checkInfo')}}</div>
      <div class="completed-info-holder">
        <div class="completed-info">
          <div class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('city')}}</span>
            <span class="request-val">{{trainingCity}}</span>
          </div>
          <div class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('topic')}}</span>
            <span class="request-val">{{TrainingRegistration_2_name}}</span>
          </div>
          <div class="request-row" v-if="trainingStartDate == trainingEndDate">
            <span class="request-name">{{$ml.with('VueJS').get('desiredDate')}}</span>
            <span class="request-val">{{periodOfDate}} {{trainingStartDate}}</span>
          </div>
          <div class="request-row" v-else>
            <span class="request-name">{{$ml.with('VueJS').get('desiredDates')}}</span>
            <span class="request-val">с {{periodOfDate}} {{trainingStartDate}} по {{trainingEndDate}} </span>
          </div>
          <div v-if="trainingPeople !== 'undefined'" class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('numberOfPersons')}}</span>
            <span class="request-val">{{trainingPeople}}</span>
          </div>
          <div v-if="trainingComment" class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('сomment')}}</span>
            <span class="request-val">{{trainingComment}}</span>
          </div>
        </div>
        <!-- <div @click="test">test</div> -->
      </div>
    </div>
    <modal
      v-show="isModalVisible"
      @close="closeModal"
    />
    <div class="section-bottom">
      <a href="#"
         class="btn btn-full-width btn-main "
         v-bind:class="{disabled: isDisabled}"
         @click="sendForm"
      >{{$ml.with('VueJS').get('signUp')}}</a>
    </div>
  </div>
</template>

<script>
// import Step3 from './TrainingRegistration_3'
import modal from '../modal/ModalErrorSender'
export default {
  data () {
    return {
      isDisabled: false,
      isModalVisible: false,
      resultSender: '',
      trainingCity: localStorage.getItem('trainingCity'),
      TrainingRegistration_2_name: localStorage.getItem('TrainingRegistration_2_name'),
      trainingStartDate: localStorage.getItem('trainingStartDate'),
      trainingEndDate: localStorage.getItem('trainingEndDate'),
      trainingPeople: localStorage.getItem('trainingPeople'),
      trainingComment: localStorage.getItem('trainingComment'),
      // trainingCity: localStorage.getItem('trainingCity'),
      periodOfDate: null
    }
  },
  components: {
    // 'step3': Step3
    modal
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    async sendForm (event) {
      event.preventDefault()
      this.isDisabled = true
      const res = {
        'region': this.trainingCity,
        'theme': this.TrainingRegistration_2_name,
        'date_start': this.trainingStartDate,
        'date_end': this.trainingEndDate,
        'member_count': this.trainingPeople,
        'user_id': localStorage.userId
      }
      if (this.trainingComment) {
        res['description'] = this.trainingComment
      }

      await this.$http.post('https://nfm.com.ua/index.php?route=api/app/edu/', res, { emulateJSON: true })
      // await resourcess.save({}, res)
        .then(response => { this.resultSender = response.status })
        .catch(response => { this.resultSender = response.status })
      if (this.resultSender === 200) {
        this.$router.push('SuccessTraining')
      } else {
        this.showModal()
      }
    },
    successTeaching (event) {
      event.preventDefault()
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

      var urlencoded = new URLSearchParams()
      urlencoded.append('region', this.trainingCity)
      urlencoded.append('theme', this.TrainingRegistration_2_name)
      urlencoded.append('date_start', this.trainingStartDate)
      urlencoded.append('date_end', this.trainingEndDate)
      urlencoded.append('member_count', this.trainingPeople)
      urlencoded.append('description', this.trainingComment)
      urlencoded.append('user_id', localStorage.userId)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      }
      fetch('https://nfm.com.ua/index.php?route=api/app/edu/', requestOptions)
        .then(response => { this.resultSender = response.status })
        .catch(response => { this.resultSender = response.status })
      if (this.resultSender === 200) {
        this.$router.push('SuccessTraining')
      } else {
        this.showModal()
      }
      // .catch(error => console.log(error))
      // if (this.resultSender === 200) {
      //   this.$router.push('SuccessTraining')
      // } else {
      //   console.log(222222222222222)
      // }
      // this.$router.push('SuccessTraining')
    }
  }
}
</script>
