<template>
  <div>
    <template v-if="ratingVal<5 || ratingVal ==null">
      <modal name="modal-rating" :adaptive="true" :resizable="true" height="auto" id="modal-rating">
        <h3>{{$ml.with('VueJS').get('rateService')}}</h3>
        <div class="modal-body">
          <p>{{$ml.with('VueJS').get('rateServiceMsg')}}</p>

          <form class="form-rating" @submit.prevent="formSend()" method="post">
            <div class="rating-row">
              <div class="rating-stars">
                <input
                  id="input5"
                  name="rating"
                  type="radio"
                  value="5"
                  class="rating-check"
                  v-model="ratingVal"
                  v-on:change="ratingSend()"
                />
                <label for="input5" class="rating-star">
                  <i class="ic-star"></i>
                </label>
                <input
                  id="input4"
                  name="rating"
                  type="radio"
                  value="4"
                  class="rating-check"
                  v-model="ratingVal"
                  v-on:change="ratingSend()"
                />
                <label for="input4" class="rating-star">
                  <i class="ic-star"></i>
                </label>
                <input
                  id="input3"
                  name="rating"
                  type="radio"
                  value="3"
                  class="rating-check"
                  v-model="ratingVal"
                  v-on:change="ratingSend()"
                />
                <label for="input3" class="rating-star">
                  <i class="ic-star"></i>
                </label>
                <input
                  id="input2"
                  name="rating"
                  type="radio"
                  value="2"
                  class="rating-check"
                  v-model="ratingVal"
                  v-on:change="ratingSend()"
                />
                <label for="input2" class="rating-star">
                  <i class="ic-star"></i>
                </label>
                <input
                  id="input1"
                  name="rating"
                  type="radio"
                  value="1"
                  class="rating-check"
                  v-model="ratingVal"
                  v-on:change="ratingSend()"
                />
                <label for="input1" class="rating-star">
                  <i class="ic-star"></i>
                </label>
              </div>
            </div>
            <transition name="fade">
              <template v-if="ratingVal<5 && ratingVal !=null">
                <div class="rating-comment">
                  <p>{{$ml.with('VueJS').get('rateServiceComment')}}</p>
                  <div
                    class="form-group"
                    v-bind:class="{'form-group-error': $v.ratingComment.$error}"
                  >
                    <textarea
                      name="text"
                      class="textarea textarea-sm"
                      v-model="ratingComment"
                      @input="$v.ratingComment.$touch()"
                      @blur="$v.ratingComment.$touch()"
                    ></textarea>
                    <span
                      class="icon icon-error"
                      aria-label="icon error"
                      v-if="$v.ratingComment.$error"
                    ></span>
                    <div class="error" v-if="$v.ratingComment.$error">{{$ml.with('VueJS').get('rateServiceTxt')}}</div>
                  </div>
                  <button type="submit" class="btn btn-main btn-modal-fixed">{{$ml.with('VueJS').get('send')}}</button>
                </div>
              </template>
            </transition>
          </form>
        </div>
      </modal>
    </template>

    <modal
      name="modal-rating-thx"
      class="modal-rating-thx"
      :adaptive="true"
      :resizable="true"
      height="auto"
      @opened="openModal"
    >
      <div class="modal-title">{{$ml.with('VueJS').get('rateServiceSuccess')}}</div>
    </modal>
  </div>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal-body p {
  font-size: .9375rem;
  margin: 0 0 15px;
}
.modal-body .rating-stars {
  padding-right: 0;
}
#modal-rating h3 {
  margin: 0;
}
.modal-rating-thx .modal-title {
  margin-bottom: 0;
}
</style>
<script>
import { required } from 'vuelidate/dist/validators.min'

export default {
  props: ['onLogin'],
  data () {
    return {
      ratingComment: null,
      ratingVal: null,
      requestForm: null,
      result: null
    }
  },
  validations: {
    ratingComment: {
      required
    }
  },
  methods: {
    openModal (event) {
      var self = this
      setTimeout(function () {
        self.$modal.hide('modal-rating-thx')
      }, 2500)
    },
    formSend () {
      console.log(localStorage)
      const rate = {
        'rating': this.ratingVal,
        'msg': this.ratingComment,
        'service_id': this.$route.params['id'],
        'author': localStorage.userId
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$v.$reset()
        // this.ratingVal = null;
        /* post the form */
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
          // console.log(rate)
          this.ratingPost(rate)
          this.ratingComment = ''
          this.$modal.hide('modal-rating')
          // console.log('Form submitted')
        }, 500)
      }
    },
    async ratingPost (rate) {
      await this.$http.post('https://nfm.com.ua/index.php?route=api/app/service_msg/', rate, { emulateJSON: true })
        .then(response => {
          this.result = response
        })
    },
    ratingSend () {
      if (this.ratingVal > 4 && this.ratingVal != null) {
        const rate = {
          'rating': 5,
          'msg': '',
          'service_id': this.$route.params['id'],
          'author': localStorage.userId
        }
        this.ratingPost(rate)
        this.$modal.show('modal-rating-thx')
      }
      this.onLogin({
        ratingVal: this.ratingVal
      })
    }
  },
  watch: {
    serviceRating (rate) {
      localStorage.serviceRating = rate
    },
    serviceMessage (message) {
      localStorage.serviceMessage = message
    }
  }
}
</script>
