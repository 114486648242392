<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/ServiceRequests" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{
            $ml.with("VueJS").get("createNewRequestTitle")
          }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="form-holder">
        <form @submit.prevent="sendPostForm()" id="new_request">
            <div
               v-if="holdings.length"
               class="form-group"
             >
              <v-select
                id="select_holding"
                name="select_holding"
                class="select"
                :options="holdings"
                :get-option-label="formatHoldingLabel"
                :searchable="false"
                :clearable="false"
                :components="{ OpenIndicator }"
                v-model="selectHolding"
                v-bind:class="{ 'has-value': selectHolding }"
                :reduce="holding => holding"
              ></v-select>
              <label for="select_holding" class="label">{{
                $ml.with("VueJS").get("repairHolding")
              }}</label>
            </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.brandName.$error }"
          >
            <div
              class="dropdown-input select "
              :class="{
                'service-ticket__text-select':
                  !$v.brandName.$error &&
                  !this.focusedBrand &&
                  agroEquipment.length > 0,
                'service-ticket__text-select--open':
                  !$v.brandName.$error &&
                  this.focusedBrand &&
                  agroEquipment.length > 0
              }"
            >
              <input
                id="brand_name  "
                name="brand_name"
                type="text"
                v-model="brandName"
                @input="$v.brandName.$touch()"
                @blur="$v.brandName.$touch()"
                @focus="
                  (focusedBrand = true),
                    (focusedTech = false),
                    (focusedModel = false),
                    (focusedSerialNumber = false),
                    (onSendToAutocompleteData('brand'))
                "
                @change="changeBrand"
                class="input"
                v-bind:class="{ 'has-value': $v.brandName.$model }"
              />
              <label for="brand_name" class="label">{{
                $ml.with("VueJS").get("brand")
              }}</label>
              <ul
                class="vs__dropdown-menu"
                ref="brandItem"
                v-show="this.focusedBrand && autoCompleteBrands.length > 0"
              >
                <transition v-for="(item, index) in autoCompleteBrands" :key="index">
                  <li
                    role="option"
                    class="vs__dropdown-option"
                    @click="selectElement('brand',item)"
                  >
                    {{ item.brand }}
                  </li>
                </transition>
              </ul>
              <!-- <ul
                class="vs__dropdown-menu"
                ref="brandItem"
                v-show="this.focusedBrand && agroEquipment.length > 0"
              >
                <transition v-for="(item, index) in agroEquipment" :key="index">
                  <li
                    v-if="brandName === null"
                    role="option"
                    class="vs__dropdown-option"
                    @click="selectBrand(item)"
                  >
                    {{ item }}
                  </li>
                  <li
                    v-else-if="
                      item.toLowerCase().indexOf(brandName.toLowerCase()) !== -1
                    "
                    role="option"
                    class="vs__dropdown-option"
                    @click="selectBrand(item)"
                  >
                    {{ item }}
                  </li>
                </transition>
              </ul> -->
            </div>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.brandName.$error"
            ></span>
            <div class="error" v-if="$v.brandName.$error">
              {{ $ml.with("VueJS").get("setBrand") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.techName.$error }"
          >
            <div
              class="dropdown-input select "
              :class="{
                'service-ticket__text-select':
                  !$v.techName.$error &&
                  !focusedTech &&
                  agroTechnicians.length > 0,
                'service-ticket__text-select--open':
                  !$v.techName.$error &&
                  focusedTech &&
                  agroTechnicians.length > 0
              }"
            >
              <input
                id="tech_name"
                name="tech_name"
                type="text"
                v-model="techName"
                @input="$v.techName.$touch()"
                @blur="$v.techName.$touch()"
                @focus="
                  (focusedTech = true),
                    (focusedModel = false),
                    (focusedBrand = false),
                    (focusedSerialNumber = false),
                    (onSendToAutocompleteData('type'))
                "
                @change="changeTech"
                class="input"
                v-bind:class="{ 'has-value': $v.techName.$model }"
              />
              <label for="tech_name" class="label">{{
                $ml.with("VueJS").get("techName")
              }}</label>
                <ul
                class="vs__dropdown-menu"
                ref="techItem"
                v-if="focusedTech && autocompleteTypes.length > 0"
              >
                <transition
                  v-for="(item, index) in autocompleteTypes"
                  :key="index"
                >
                  <li
                    role="option"
                    class="vs__dropdown-option"
                    @click="selectElement('type',item)"
                  >
                    {{ item.name }}
                  </li>
                </transition>
              </ul>
              <!-- <ul
                class="vs__dropdown-menu"
                ref="techItem"
                v-if="focusedTech && agroTechnicians.length > 0"
              >
                <transition
                  v-for="(item, index) in agroTechnicians"
                  :key="index"
                >
                  <li
                    v-if="techName === null"
                    role="option"
                    class="vs__dropdown-option"
                    @click="selectTech(item)"
                  >
                    {{ item }}
                  </li>
                  <li
                    v-else-if="
                      item.toLowerCase().indexOf(techName.toLowerCase()) !== -1
                    "
                    role="option"
                    class="vs__dropdown-option"
                    @click="selectTech(item)"
                  >
                    {{ item }}
                  </li>
                </transition>
              </ul> -->
            </div>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.techName.$error"
            ></span>
            <div class="error" v-if="$v.techName.$error">
              {{ $ml.with("VueJS").get("setTechName") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.modelName.$error }"
          >
            <div
              class="dropdown-input select"
              :class="{
                'service-ticket__text-select':
                  !$v.modelName.$error && !focusedModel && agroModel.length > 0,
                'service-ticket__text-select--open':
                  !$v.modelName.$error && focusedModel && agroModel.length > 0
              }"
            >
              <input
                id="model_name"
                name="model_name"
                type="text"
                v-model="modelName"
                @input="$v.modelName.$touch()"
                @blur="$v.modelName.$touch()"
                @focus="
                  (focusedModel = true),
                    (focusedBrand = false),
                    (focusedTech = false),
                    (focusedSerialNumber = false),
                    (onSendToAutocompleteData('model'))
                "
                @change="changeModel"
                class="input"
                v-bind:class="{ 'has-value': $v.modelName.$model }"
              />
              <label for="model_name" class="label">{{
                $ml.with("VueJS").get("model")
              }}</label>
              <ul
                class="vs__dropdown-menu"
                ref="modelItem"
                v-if="focusedModel && autocompleteModels.length > 0"
              >
                <transition v-for="(item, index) in autocompleteModels" :key="index">
                  <li
                    role="option"
                    class="vs__dropdown-option"
                    @click="
                      selectElement('model', item)
                    "
                  >
                    {{ item.techmodel }}
                  </li>
                </transition>
              </ul>
              <!-- <ul
                class="vs__dropdown-menu"
                ref="modelItem"
                v-if="focusedModel && agroModel.length > 0"
              >
                <transition v-for="(item, index) in agroModel" :key="index">
                  <li
                    v-if="modelName === null"
                    role="option"
                    class="vs__dropdown-option"
                    @click="
                      selectModel(item.brand, item.technicians, item.model)
                    "
                  >
                    {{ item.model }}
                  </li>
                  <li
                    v-else-if="
                      item.model
                        .toLowerCase()
                        .indexOf(modelName.toLowerCase()) !== -1
                    "
                    role="option"
                    class="vs__dropdown-option"
                    @click="
                      selectModel(item.brand, item.technicians, item.model)
                    "
                  >
                    {{ item.model }}
                  </li>
                </transition>
              </ul> -->
            </div>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.modelName.$error"
            ></span>
            <div class="error" v-if="$v.modelName.$error">
              {{ $ml.with("VueJS").get("setModel") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.serialNumber.$error }"
          >
            <div
              class="dropdown-input select"
              :class="{
                'service-ticket__text-select':
                  !$v.serialNumber.$error &&
                  !focusedSerialNumber &&
                  agroSerial.length > 0,
                'service-ticket__text-select--open':
                  !$v.serialNumber.$error &&
                  focusedSerialNumber &&
                  agroSerial.length > 0
              }"
            >
              <input
                id="serial_number"
                name="serial_number"
                type="text"
                v-model="serialNumber"
                @input="$v.serialNumber.$touch()"
                @blur="$v.serialNumber.$touch()"
                @focus="
                  (focusedSerialNumber = true),
                    (focusedBrand = false),
                    (focusedModel = false),
                    (focusedTech = false),
                    (onSendToAutocompleteData('serial'))
                "
                @change="changeSerialNumber"
                class="input"
                v-bind:class="{ 'has-value': $v.serialNumber.$model }"
              />
              <label for="serial_number" class="label">{{
                $ml.with("VueJS").get("serialNum")
              }}</label>
               <ul
                class="vs__dropdown-menu"
                ref="serialItem"
                v-if="focusedSerialNumber && autocompleteSerial.length > 0"
              >
                <transition v-for="(item, index) in autocompleteSerial" :key="index">
                  <li
                    role="option"
                    class="vs__dropdown-option"
                    @click="
                      selectElement(
                        'serial',
                        item
                      )
                    "
                  >
                    {{ item.serialnumber }}
                  </li>
                </transition>
              </ul>
              <!-- <ul
                class="vs__dropdown-menu"
                ref="serialItem"
                v-if="focusedSerialNumber && agroSerial.length > 0"
              >
                <transition v-for="(item, index) in agroSerial" :key="index">
                  <li
                    v-if="serialNumber === null"
                    role="option"
                    class="vs__dropdown-option"
                    @click="
                      selectSerial(
                        item.brand,
                        item.technicians,
                        item.model,
                        item.serial
                      )
                    "
                  >
                    {{ item.serial }}
                  </li>
                  <li
                    v-else-if="
                      item.serial
                        .toLowerCase()
                        .indexOf(serialNumber.toLowerCase()) !== -1
                    "
                    role="option"
                    class="vs__dropdown-option"
                    @click="
                      selectSerial(
                        item.brand,
                        item.technicians,
                        item.model,
                        item.serial
                      )
                    "
                  >
                    {{ item.serial }}
                  </li>
                </transition>
              </ul> -->
            </div>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.serialNumber.$error"
            ></span>
            <div class="error" v-if="$v.serialNumber.$error">
              {{ $ml.with("VueJS").get("setSerialNum") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.selectCategory.$error }"
          >
            <v-select
              id="select_category"
              name="select_category"
              class="select"
              label="categoryType"
              :options="options"
              :searchable="false"
              :clearable="false"
              :components="{ OpenIndicator }"
              @change="$v.select.$touch()"
              @blur="$v.selectCategory.$touch()"
              v-model="selectCategory"
              v-bind:class="{ 'has-value': $v.selectCategory.$model }"
              :reduce="categoryType => categoryType.categoryCode"
            ></v-select>
            <label for="select_category" class="label">{{
              $ml.with("VueJS").get("repairСategory")
            }}</label>
            <div class="error" v-if="$v.selectCategory.$error">
              {{ $ml.with("VueJS").get("setRepairСategory") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.selectRegion.$error }"
          >
            <v-select
              id="select_region"
              name="select_location"
              class="select"
              label="name"
              :options="regions"
              :searchable="false"
              :clearable="false"
              :components="{ OpenIndicator }"
              v-model="selectRegion"
              v-bind:class="{ 'has-value': $v.selectRegion.$model }"
              :reduce="region => region.id"
            ></v-select>
            <label for="select_region" class="label">{{
              $ml.with("VueJS").get("vehicleRegion")
            }}</label>
            <div class="error" v-if="$v.selectRegion.$error">
              {{ $ml.with("VueJS").get("setFieldRequired") }}
            </div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.selectDistrict.$error }"
          >
            <v-select
              id="select_district"
              name="select_location"
              class="select"
              label="name"
              :options="districts"
              :searchable="false"
              :clearable="false"
              :components="{ OpenIndicator }"
              v-model="selectDistrict"
              v-bind:class="{ 'has-value': $v.selectDistrict.$model }"
              :reduce="district => district.id"
            ></v-select>
            <label for="select_district" class="label">{{
              $ml.with("VueJS").get("vehicleDistrict")
            }}</label>
            <div class="error" v-if="$v.selectDistrict.$error">
              {{ $ml.with("VueJS").get("setFieldRequired") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.localityName.$error }"
          >
            <input
              id="locality_name"
              name="locality_name"
              v-model.trim="$v.localityName.$model"
              @input="$v.localityName.$touch()"
              @blur="$v.localityName.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.localityName.$model }"
            />
            <label for="locality_name" class="label">{{
              $ml.with("VueJS").get("locality")
            }}</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.localityName.$error"
            ></span>
            <div class="error" v-if="$v.localityName.$error">
              {{ $ml.with("VueJS").get("setLocality") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.selectServiceCentre.$error }"
          >
            <v-select
              id="select_service_centre"
              name="select_location"
              class="select"
              label="name"
              :options="serviceCentres"
              :searchable="false"
              :clearable="false"
              :components="{ OpenIndicator }"
              v-model="selectServiceCentre"
              v-bind:class="{ 'has-value': $v.selectServiceCentre.$model }"
              :reduce="serviceCentre => serviceCentre.id"
            ></v-select>
            <label for="select_service_centre" class="label">{{
              $ml.with("VueJS").get("serviceCentre")
            }}</label>
            <div class="error" v-if="$v.selectServiceCentre.$error">
              {{ $ml.with("VueJS").get("setFieldRequired") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.workingHours.$error }"
          >
            <input
              id="workingHours"
              name="workingHours"
              v-model.trim="$v.workingHours.$model"
              @input="$v.workingHours.$touch()"
              @blur="$v.workingHours.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.workingHours.$model }"
            />
            <label for="workingHours" class="label">{{
              $ml.with("VueJS").get("workingHours")
            }}</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.workingHours.$error"
            ></span>
            <div class="error" v-if="$v.workingHours.$error">
              {{ $ml.with("VueJS").get("setWorkingHours") }}
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.faultDescription.$error }"
          >
            <textarea
              id="fault_description"
              name="text"
              class="textarea textarea-sm"
              v-model="faultDescription"
              @input="$v.faultDescription.$touch()"
              @blur="$v.faultDescription.$touch()"
              v-bind:class="{ 'has-value': $v.faultDescription.$model }"
            ></textarea>
            <label for="fault_description" class="label">{{
              $ml.with("VueJS").get("faultDescription")
            }}</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.faultDescription.$error"
            ></span>
            <div class="error" v-if="$v.faultDescription.$error">
              {{ $ml.with("VueJS").get("setFaultDescription") }}
            </div>
          </div>

          <div class="form-group form-group-last">
            <div class="file-holder">
              <div v-for="(file, key) in files" class="file-listing" :key="key">
                <img class="preview" v-bind:ref="'image' + parseInt(key)" />
                <span class="file-remove" v-on:click="removeImage(key)"></span>
              </div>
            </div>
            <label class="input-file-label">
              <input
                type="file"
                id="files"
                ref="files"
                accept="image/*"
                name="file"
                v-on:change="handleFilesUpload()"
              />
              <i v-if="!hideInputFile" class="icon icon-file"></i>
              <div v-if="!hideInputFile" class="input-file-text">
                {{ $ml.with("VueJS").get("setFile") }}
              </div>
            </label>
          </div>
        </form>
      </div>
      <user-info />
    </div>
    <div class="section-bottom">
      <button
        type="submit"
        class="btn btn-full-width btn-main"
        form="new_request"
      >
        {{ $ml.with("VueJS").get("SendRequest2") }}
      </button>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/dist/validators.min";
import "vue-select/dist/vue-select.css";
import {
  GET_REGIONS,
  GET_DISTRICTS,
  GET_SERVICE_CENTRES,
  GET_HOLDINGS,
  GET_AUTOCOMPLETE_BRANDS,
  GET_AUTOCOMPLETE_TYPES,
  GET_AUTOCOMPLETE_MODELS,
  GET_AUTOCOMPLETE_SERIALS
} from "../../helpers/requests";

import UserInfo from "./UserInfo.vue";
const mustBeCool = function(value) {
  if (this.selectCategory !== "100456") {
    return true;
  } else {
    if (value) {
      return true;
    }
  }
  return false;
};

export default {
  data() {
    return {
      workingHours: null,
      brandName: null,
      modelName: null,
      techName: null,
      serialNumber: null,
      localityName: null,
      faultDescription: null,
      submitStatus: null,
      select: null,
      selectCategory: null,
      selectHolding: null,
      hideInputFile: false,
      files: [],
      visible: true,
      OpenIndicator: {
        render: createElement => createElement("span")
      },
      focusedBrand: false,
      focusedTech: false,
      focusedModel: false,
      focusedSerialNumber: false,
      regions: [],
      districts: [],
      holdings: [],
      serviceCentres: [],
      selectRegion: null,
      selectDistrict: null,
      selectServiceCentre: null,
      userId: localStorage.getItem("userId"),

      options: [
        {
          categoryCode: "100744 ",
          categoryType: "Проблеми з електронікою"
        },
        {
          categoryCode: "100747",
          categoryType: "Несправність двигуна"
        },
        {
          categoryCode: "100704",
          categoryType: "Ходова частина"
        },
        {
          categoryCode: "100452",
          categoryType: "Несправність кондиціонера"
        },
        {
          categoryCode: "100433",
          categoryType: "Несправність навігаційної системи"
        },
        {
          categoryCode: "100419",
          categoryType: "Проблеми з КПП"
        },
        {
          categoryCode: "100418",
          categoryType: "Проблеми з гідравлічною системою"
        },
        {
          categoryCode: "100456",
          categoryType: "Інші проблеми"
        },
        {
          categoryCode: "161000",
          categoryType: "Технічне обслуговування (ТО)"
        },
        {
          categoryCode: "160309",
          categoryType: "Встановлення навігаційного обладнання"
        },
        {
          categoryCode: "160320",
          categoryType: "Розблокування сигналу"
        }
      ],
      agroEquipment: [
        // {
        //   'brand': 'New Holland',
        //   'technicians': 'Трактор',
        //   'model': 'T6050',
        //   'serial': 'ZCBD06186'
        // },
        // {
        //   'brand': 'Case IH',
        //   'technicians': 'Комбайн',
        //   'model': '2388',
        //   'serial': '0197285'
        // }
      ],
      agroTechnicians: [],
      agroModel: [],
      agroSerial: [],
      autoCompleteBrands: [],
      autocompleteTypes: [],
      autocompleteModels: [],
      autocompleteSerial: []
    };
  },
  components: {
    UserInfo
  },

  validations: {
    selectRegion: {
      required
    },
    selectDistrict: {
      required
    },
    selectServiceCentre: {
      required
    },
    selectCategory: {
      required
    },
    brandName: {
      required,
      minLength: minLength(4)
    },
    techName: {
      required
    },
    modelName: {
      required
    },
    serialNumber: {
      required,
      minLength: minLength(6)
    },
    localityName: {
      required
    },
    faultDescription: {
      // required
      mustBeCool
    },
    workingHours: {
      required,
      minLength: minLength(2)
    }
  },

  async created() {
    try {
      await this.getHoldings();
      await this.getRegions();
      await this.getServiceCentres();
    } catch (err) {
      console.log(err.message);
    }
  },

  watch: {
    selectHolding(newVal, oldVal) {
      console.log({ newVal })
      console.log({ oldVal })
    },

    async selectRegion() {
      if (this.selectRegion) {
        try {
          await this.getDistricts(this.selectRegion);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  },

  methods: {
    async onSendToAutocompleteData(input) {
      let userId = this.selectHolding ? this.selectHolding.id : localStorage.userId
      if (input === 'brand') {
        const response = await GET_AUTOCOMPLETE_BRANDS(this.brandName, userId);
        const result = await response.json();
        this.autoCompleteBrands = result
      } else if (input === 'type') {
        const response = await GET_AUTOCOMPLETE_TYPES(this.techName,this.brandName,userId)
        const result = await response.json()
        this.autocompleteTypes = result
      } else if (input === 'model') {
        const response = await GET_AUTOCOMPLETE_MODELS(this.modelName, this.brandName, this.techName, userId)
        const result = await response.json()
        this.autocompleteModels = result
      } else if (input === 'serial') {
        const response = await GET_AUTOCOMPLETE_SERIALS(this.serialNumber, this.brandName, this.techName, this.modelName, userId)
        const result = await response.json()
        this.autocompleteSerial = result
      }
    },
    selectElement(type, item) {
      if (type === 'brand') {
       this.brandName = item.brand 
       this.autoCompleteBrands = []
      }
      if (type === 'type') {
        this.techName = item.name
        this.autocompleteTypes = [] 
      }
      if (type === 'model') {
        this.modelName = item.techmodel
        this.autocompleteModels = []
      }
      if (type === 'serial') {
        this.serialNumber = item.serialnumber
        this.autocompleteSerial = []
      }
    },
    selectBrand(brand) {
      this.focusedBrand = false;
      this.brandName = brand;
      this.agroTechnicians = [];
      this.$http
        .get(
          "https://nfm.com.ua/index.php?route=api/app/user_tech/" +
            localStorage.userId +
            "&brand=" +
            brand
        )
        .then(response => response.json())
        .then(response => {
          // console.log(response)
          for (let i in response) {
            if (!this.agroTechnicians.includes(response[i].technicians)) {
              this.agroTechnicians.push(response[i].technicians);
            }
            // console.log(response[i])
          }
          // console.log(this.agroTechnicians)
          // this.agroTechnicians = response
        });
    },

    formatHoldingLabel(option) {
      return `${option.company} (${option.name})`
    },

    selectTech(tech) {
      let brand = this.brandName;
      this.focusedTech = false;
      this.techName = tech;
      this.$http
        .get(
          "https://nfm.com.ua/index.php?route=api/app/user_tech/" +
            localStorage.userId +
            "&brand=" +
            brand +
            "&technicians=" +
            tech
        )
        .then(response => response.json())
        .then(response => {
          this.agroModel = response;
          // console.log(this.agroModel)
        });
    },
    selectModel(brand, tech, model) {
      this.focusedModel = false;
      this.modelName = model;
      this.$http
        .get(
          "https://nfm.com.ua/index.php?route=api/app/user_tech/" +
            localStorage.userId +
            "&brand=" +
            brand +
            "&technicians=" +
            tech +
            "&model=" +
            model
        )
        .then(response => response.json())
        .then(response => {
          this.agroSerial = response;
          // console.log(this.agroSerial)
        });
    },
    selectSerial(brand, tech, model, serial) {
      this.focusedSerialNumber = false;
      this.serialNumber = serial;
    },
    sendPostForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        window.scrollTo(0, 0);
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var formdata = new FormData();
        formdata.append("status", 1);
        // formdata.append('product_name', 'product name')
        formdata.append("user_id", localStorage.userId);
        if (this.faultDescription) {
          formdata.append("description", this.faultDescription);
        }
        // formdata.append('region', 'region')
        formdata.append("region", this.selectRegion);
        formdata.append("district", this.selectDistrict);
        formdata.append("service", this.selectServiceCentre);
        formdata.append("technicians", this.techName);
        formdata.append("model", this.modelName);
        formdata.append("brand", this.brandName);
        formdata.append("repair", this.selectCategory);
        formdata.append("city", this.localityName);
        formdata.append("serial", this.serialNumber);
        formdata.append("working_hours", this.workingHours);

      if (this.selectHolding) {
        formdata.append("customer_id", this.selectHolding.id);
        formdata.append("responsible_name", this.selectHolding.name);
        formdata.append("responsible_phone", this.selectHolding.phone);
        formdata.append("responsible_email", this.selectHolding.email);
      }
        
        if (localStorage.responsibleName) {
          formdata.append("responsible_name", localStorage.responsibleName);
        } else {
          formdata.append("responsible_name", localStorage.userName);
        }

        if (localStorage.responsibleEmail) {
          formdata.append("responsible_email", localStorage.responsibleEmail);
        } else {
          formdata.append("responsible_email", localStorage.userEmail);
        }

        if (localStorage.responsiblePhone) {
          formdata.append("responsible_phone", localStorage.responsiblePhone);
        } else {
          formdata.append("responsible_phone", localStorage.userPhone);
        }

        if (this.$refs.files.files[0]) {
          formdata.append(
            "photo",
            this.$refs.files.files[0],
            this.$refs.files.files[0].name
          );
        }
        console.log({formdata});
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow"
        };
        fetch(
          "https://nfm.com.ua/index.php?route=api/app/service_orders/",
          requestOptions
        )
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log("error", error));
        this.$router.push("SuccessTicketForm");
        this.$v.$reset();
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      }).then(() => {
        console.log('Cache cleared');
      });
      localStorage.setItem("responsibleName", "");
      localStorage.setItem("responsibleEmail", "");
      localStorage.setItem("responsiblePhone", "");
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      this.hideInputFile = true;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }

      this.getImagePreviews();
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/\.(jpe?g|png|gif|zip|rar|pgf|txt)$/i.test(this.files[i].name)) {
          let reader = new FileReader();

          reader.addEventListener(
            "load",
            function() {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );

          reader.readAsDataURL(this.files[i]);
        }
      }
    },
    removeImage(key) {
      this.files.splice(key, 1);
      this.getImagePreviews();
      this.hideInputFile = false;
    },
    // submitRequestForm () {
    //   this.$v.$touch()
    //   if (this.$v.$invalid) {
    //     this.submitStatus = 'ERROR'
    //     window.scrollTo(0, 0)
    //   } else {
    //     this.$router.push('SuccessSendForm')
    //     this.$v.$reset()
    //     // do your submit logic here
    //     this.submitStatus = 'PENDING'
    //     setTimeout(() => {
    //       this.submitStatus = 'OK'
    //     }, 500)
    //   }
    // },
    changeBrand() {
      if (this.focusedBrand === false) {
        this.focusedBrand = true;
      }
    },
    changeTech() {
      if (this.focusedTech === false) {
        this.focusedTech = true;
      }
    },
    changeModel() {
      if (this.focusedModel === false) {
        this.focusedModel = true;
      }
    },
    changeSerialNumber() {
      if (this.focusedSerialNumber === false) {
        this.focusedSerialNumber = true;
      }
    },
    // closeBrand (e) {
    //   if (!this.$el.contains(e.target)) {
    //     console.log(e.target)
    //     this.focusedBrand = false
    //   }
    // },
    async loadJson() {
      // var requestOptions = {
      //   method: 'GET',
      //   redirect: 'follow'
      // }
      // fetch('https://nfm2.test-lemon.com/index.php?route=api/app/user_tech/315', requestOptions)
      //   .then(response => {
      //     console.log(response.bodyText)
      //     this.agroEquipment = response.json()
      //     console.log(this.agroEquipment)
      //   })
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error))

      // await this.$http.get('https://nfm2.test-lemon.com/index.php?route=api/app/user_tech/315')
      await this.$http
        .get(
          "https://nfm.com.ua/index.php?route=api/app/user_tech/" +
            localStorage.userId
        )
        .then(response => response.json())
        .then(response => {
          // this.agroEquipment = response
          // console.log(response)
          for (let i in response) {
            if (
              !this.agroEquipment.includes(response[i].brand) &&
              response[i].brand
            ) {
              this.agroEquipment.push(response[i].brand);
            }
          }
        });
    },

    async getHoldings() {
      const response = await GET_HOLDINGS(this.userId);
      const result = await response.json();

      if (!response.ok) {
        console.log({ err: result });
        return;
      }
      console.log({result})
      this.holdings = result;
    },

    async getRegions() {
      const response = await GET_REGIONS();
      const result = await response.json();

      if (!response.ok) {
        console.log({ err: result });
        return;
      }
      console.log({result})
      this.regions = result;
    },

    async getDistricts(regionId) {
      const response = await GET_DISTRICTS(regionId);
      const result = await response.json();

      if (!response.ok) {
        console.log({ err: result });
        return;
      }

      this.districts = result;
    },

    async getServiceCentres() {
      const response = await GET_SERVICE_CENTRES();
      const result = await response.json();

      if (!response.ok) {
        console.log({ err: result });
        return;
      }

      this.serviceCentres = result;
    }
  },

  mounted() {
    this.loadJson();
  }
};
</script>

<style scoped>
.btn-holder .btn.btn-link-red {
  margin-right: 20px;
}
.page-container {
  height: auto;
}

.service-ticket__text-select {
  position: relative;
}

.service-ticket__text-select::after {
  content: "";
  width: 12px;
  height: 10px;
  z-index: 100;
  position: absolute;
  right: 18px;
  top: 18px;
  background: url("../../images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.service-ticket__text-select--open::after {
  content: "";
  width: 12px;
  height: 10px;
  z-index: 100;
  position: absolute;
  right: 18px;
  top: 18px;
  background: url("../../images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  animation-name: arrow-rotate;
  animation-duration: 500ms;
}

@keyframes arrow-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
</style>
