<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/SearchItemsResult" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('analogs')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="product-item-detail">
        <span>{{itemStorage.name}}</span>
      </div>
      <div class="product-items">
        <template v-for="item in itemStorage.analog">
          <div class="product-item-holder" v-bind:key="item.id">
            <div class="product-item">
              <div
                v-if="!Number(item.quantity)"
                class="product-item-status"
                v-bind:class="''"
              >{{$ml.with('VueJS').get('notAvailable')}}</div>
              <div
                v-else
                class="product-item-status"
                v-bind:class="'available'"
              >{{$ml.with('VueJS').get('available')}}</div>
              <span class="product-item-link">
                <span class="product-item-name">{{item.name}}</span>
              </span>
              <span class="product-item-code">Арт. {{item.model}}</span>
              <span v-if="item.mpn === '1'" class="product-item-min_price">
                <i class="ic-best_price "></i>
                <span class="product-item-min_price-title">{{$ml.with('VueJS').get('minPrice')}}</span>
              </span>
              <template v-if="Number(item.quantity)">
                <div class="product-item-price" v-if="item.price">
                  <div class="product-item-price-num">{{((item.price * value).toFixed(2)) | format}} грн</div>
                  <div class="product-item-price-text">{{$ml.with('VueJS').get('VAT')}}</div>
                </div>
                <div class="product-item-price" v-else>
                  <div class="product-item-price-text">{{$ml.with('VueJS').get('checkPrice')}}</div>
                </div>
                <div class="product-item-buy" v-if="Number(item.quantity)">
                  <div class="product-item-count">
                    <number-input v-bind:true-value="test" v-model="value" :min="1" inline controls rounded></number-input>
                  </div>
                  <button class="btn btn-buy btn-main" @click="addToBasket(item.product_id, value, item.price)">{{$ml.with('VueJS').get('buy')}}</button>
                </div>
              </template>
              <div class="product-item-links">
                <span v-if="item.analog.length === 0" class="product-item-more">{{$ml.with('VueJS').get('analoguesN')}}</span>
                <a v-else
                  v-bind:href="'#/Analogue/' + item.product_id"
                  class="product-item-more link-red" v-on:click="updatePage(item.product_id)"
                >{{$ml.with('VueJS').get('analoguesY')}}</a>
                <a v-bind:href="'#/AvailabilityModal/' + item.product_id"
                  class="product-item-available link-red"
                >{{$ml.with('VueJS').get('checkAvailable')}}</a>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <Cart v-bind:countCart="basketVal"/>
  </div>
</template>

<script>

export default {
  data () {
    return {
      itemStorage: [],
      test: '',
      basketVal: 0,
      value: 1,
      cart: {},
      productId: this.$route.params['id']
    }
  },
  methods: {
    async loadJsonStorage () {
      this.countCartt()
      await this.$http.get('https://nfm.com.ua/index.php?route=api/app/products/' + this.productId + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
        .then(response => response.json())
        .then(response => {
          this.itemStorage = response
        })
      this.loading = false
    },
    async updatePage (id) {
      this.countCartt()
      await this.$http.get('https://nfm.com.ua/index.php?route=api/app/products/' + id + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
        .then(response => response.json())
        .then(response => {
          this.itemStorage = response
        })
      this.loading = false
    },
    setGetLocalStorage () {
      localStorage.setItem('itemsIdBasket', JSON.stringify(this.cart))
    },
    getCountCart (id, count) {
      var basketValss = JSON.parse(localStorage.getItem('itemsIdBasket'))
      var countSum = 0
      for (var ss in basketValss) {
        if (ss === id) {
          countSum += basketValss[ss].count
        }
      }
      this.basketVal = countSum

      localStorage.setItem('itemsIdBasket', JSON.stringify(this.cart))
    },
    addToBasket (id, value, price) {
      this.cart = JSON.parse(localStorage.getItem('itemsIdBasket'))
      console.log(this.cart)
      if (this.cart === null) {
        this.cart = {}
      }

      if (this.cart[id]) {
        this.cart[id]['count'] += value
      } else {
        this.cart[id] = {
          count: value,
          price: price
        }
      }
      this.setGetLocalStorage()
      this.countCartt()
    },
    countCartt () {
      let countSum = 0
      if (localStorage.getItem('itemsIdBasket')) {
        let basketValss = JSON.parse(localStorage.getItem('itemsIdBasket'))
        for (var ss in basketValss) {
          countSum += basketValss[ss].count
        }
        this.basketVal = countSum
        return this.basketVal
      }
    }
  },
  mounted () {
    console.log(localStorage)
    this.loadJsonStorage()
    this.countCartt()
  },
  filters: {
    format (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
    }
  },
  created () {
    if (localStorage.getItem('activeItem') === '1') {
      this.resource = this.$resource('products/ua/' + this.$route.params['id'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    } else {
      this.resource = this.$resource('products/' + this.$route.params['id'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    }
  }
  // watch: {
  //   $route (to, from) {
  //     this.productId = to.params['id']
  //   }
  // }
}
</script>

<style>
  .product-items .product-item-holder:first-child {
    border-top: 0;
  }
</style>
