<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">
            <i class="icon icon-repair-part"></i>
            {{$ml.with('VueJS').get('labelSpareParts')}}
          </span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <form id="search" class="form-search">
        <div class="form-group-search">
          <v-autocomplete
            :items="items"
            v-model="itemsss"
            :get-label="getLabel"
            :min-len="4"
            :wait="500"
            @update-items="update"
            :component-item="tpl"
            @item-selected="itemSelected"
            @item-clicked="itemClicked"
            :input-attrs="{name: 'input-test' , id: 'v-my-autocomplete', placeholder: $ml.with('VueJS').get('skuOrName')}"
            class="input input-search"
            @blur="inputFocus = false"
            @focus="inputFocus = true"
          ></v-autocomplete>

          <button type="submit" class="btn btn-search" v-on:click="rederectSearch"
                  v-bind:class="{'btn-active': inputFocus}"
          >{{$ml.with('VueJS').get('find')}}</button>
        </div>
      </form>
      <div class="product-item-result">
        <div class="product-item-holder">
          <div class="product-item">
            <div class="product-item-status available">{{$ml.with('VueJS').get('available')}}</div>
            <span href class="product-item-link">
              <span class="product-item-name">{{productItem.name}}</span>
            </span>
            <span class="product-item-code">{{productItem.sku}}</span>
            <div class="product-item-price">
              <div class="product-item-price-num">{{((productItem.price * this.value).toFixed(2)) | format}} грн</div>
              <div class="product-item-price-text">{{$ml.with('VueJS').get('VAT')}}</div>
            </div>
            <div class="product-item-buy">
              <div class="product-item-count">
                <number-input v-model="value" :min="1" inline controls rounded></number-input>
              </div>
              <button class="btn btn-buy btn-main" @click="addToBasket(item.value)">{{$ml.with('VueJS').get('buy')}}</button>
            </div>
            <a href class="product-item-more link-red">{{$ml.with('VueJS').get('analoguesY')}}</a>
          </div>
        </div>
      </div>
    </div>
    <Cart v-bind:countCart="countCartt()"/>
  </div>
</template>

<script>
import tpl from './ItemTemplate.vue'
// import Parts from './repair.js'
export default {
  data () {
    return {
      value: 1,
      itemsApi: [],
      price: 12500,
      basketVal: 0,
      items: [],
      tpl: tpl,
      resultSearchJson: '',
      arsNews: Array,
      urlId: '',
      productItem: [],
      cart: {},
      inputFocus: false
    }
  },
  props: {
    item: { required: true },
    searchText: { required: true }
  },
  watch: {
    $route (to, from) {
      this.urlId = to.params['id']
    }
  },
  computed: {
    itemsss () {
      return this.searchText
    }
  },
  async mounted () {
    await this.searchJsonF()
    await this.productJsonF()
  },
  methods: {
    countCartt () {
      let countSum = 0
      if (localStorage.getItem('itemsIdBasket')) {
        let basketValss = JSON.parse(localStorage.getItem('itemsIdBasket'))
        for (var ss in basketValss) {
          countSum += basketValss[ss].count
        }
        this.basketVal = countSum
        return this.basketVal
      }
    },
    rederectSearch (e) {
      e.preventDefault()
      this.$router.replace('/SearchItemsResult')
    },
    setGetLocalStorage () {
      localStorage.setItem('itemsIdBasket', JSON.stringify(this.cart))
    },
    getCountCart (id, count) {
      var basketValss = JSON.parse(localStorage.getItem('itemsIdBasket'))
      var countSum = 0
      for (var ss in basketValss) {
        if (ss === id) {
          countSum += basketValss[ss].count
        }
      }
      this.basketVal = countSum
      console.log(this.cart)
      localStorage.setItem('itemsIdBasket', JSON.stringify(this.cart))
    },
    async loadSearch (search) {
      await this.$http.get('https://nfm.com.ua/index.php?route=api/app/search&q=' + search + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
        .then(response => response.json())
        .then(response => {
          this.resultSearchJson = response
        })
    },
    searchJsonF () {
      this.searchJson.get().then(response => response.json())
        .then(response => (this.resultSearchJson = response))
    },
    productJsonF () {
      this.productJson.get().then(response => response.json())
        .then(response => (this.productItem = response))
    },
    addToBasket () {
      if (this.cart[this.$route.params['id']] === undefined) {
        this.cart[this.$route.params['id']] = this.value
      } else {
        this.cart[this.$route.params['id']] = this.value
      }
      if (localStorage.getItem('itemsIdBasket')) {
        this.getCountCart(this.$route.params['id'], this.cart[this.$route.params['id']])
      } else {
        this.setGetLocalStorage()
      }
      this.countCartt()
      // console.log(this.countCartt())
    },
    itemSelected (item) {
      console.log('Selected item!', item)
    },
    itemClicked (item) {
      console.log('You clicked an item!', item)
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    update (text) {
      this.loadSearch(text.toLowerCase())
      this.items = this.resultSearchJson.filter(item => {
        return new RegExp(text.toLowerCase()).test(item.name.toLowerCase())
      })
    }
  },
  filters: {
    format (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
    }
  },
  created () {
    if (localStorage.getItem('activeItem') === '1') {
      this.searchJson = this.$resource('search/ua&q=' + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
      this.productJson = this.$resource('products/ua/' + this.$route.params['id'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    } else {
      this.searchJson = this.$resource('search&q=' + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
      this.productJson = this.$resource('products/' + this.$route.params['id'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    }
  }
}
</script>
